import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div name="home" className="w-full h-full bg-[#16161a]">
      {/* Containter */}
      <div className="max-w-[1000px] mx-auto px-8 py-44 flex flex-col justify-center h-full">
        <p className="text-2xl sm:text-5xl text-blue-100">Hello, my name is</p>
        <h1 className="text-4xl sm:text-7xl font-bold text-[#ccd6f6]">
          Saman Raftari
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold text-[#8892b0]">
          I'm a Full Stack Developer.
        </h2>
        <p className="text-[#94a1b2] py-4 max-w-[700px]">
          I'm a full-stack developer that utilizes a combination of front end
          languages for user experience, back end development for overall design
          and function for app operation, and database creation and management.
          My skill set provides a complete solution for the creation and
          implementation of web app development.
        </p>
        <div>
          <button className="text-[#fffffe] border-2 px-6 py-3 my-2 flex items-center hover:bg-[#7f5af0] hover:border-[#7f5af0]">
            <Link to="portfolio" smooth={true} duration={500}>
              View Work
            </Link>
            <span className="hover:rotate-90 duration-300">
              <HiArrowNarrowRight className="ml-3" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
