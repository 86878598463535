import React from "react";

const About = () => {
  return (
    <div name="about" className="w-full h-full bg-[#16161a] text-[#94a1b2]">
      <div className="flex flex-col justify-center items-center py-44 w-full h-full">
        <div className="max-w-[1000px] w-full px-4 grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4">
            <p className="text-3xl text-[#fffffe] font-bold inline border-b-4 border-[#7f5af0]">
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="sm:text-right text-4xl font-bold">
            <p className="text-[#94a1b2]">
              Hello! I'm Saman, nice to meet you! Please take a look around.
            </p>
          </div>
          <div>
            <p className="text-[#72757e]">
              Full Stack Developers play a vital role in streamlining your
              project and bringing it to fruition. Typically a developer would
              come on at the beginning of your project to help convert user
              requirements into a design plan for your intended use of a web
              application. This process usually requires the involvment of
              non-technical teams and key groups within the firm, working in
              concert with the developer to achieve the end product.
            </p>
          </div>
          <div>
            <p className="text-[#72757e]">
              Without a Full Stack Developer on your team who can put together
              and visualize all the key elements of your web application,
              mistakes and miscommunications will occur which will decrease the
              overall efficiency of your team, as well as the quality of the end
              product. This situation can easily lead to a much higher
              investment in your web application, and delay bringing your
              application to market, potentially harming your business in this
              competitive atmosphere.
            </p>
          </div>
          <div>
            <p className="text-2xl text-[#94a1b2] font-bold">Are you ready for the future?</p>
            <p className="text-[#72757e] mb-[20vh]">
              Taking into consideration the technological competitiveness in
              todays business world built on web 2.0, and the cutting edge of
              tech being worked on for web 3.0 (which is just on the horizon),
              does your company have the developers it needs to stay ahead? I
              can make that happen, email me.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
